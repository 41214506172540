import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';

const atualizarValoresPageSteps = (intl, resources) => {

  const feature = {
    id: 3,
    descricao: 'atualizarValoresProjetos',
  };
  
  return [
    {
      selector: '.iniciando-tour',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'iniciandoTour' })
      ),
      position: [20, 20]
    },
    {
      selector: '.valores-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'configureFiltros' }, { projetos: resources.projetos })
      ),
    },
    {
      selector: '.valores-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'incluirRemoverColunas' }
        )
      ),
    },
    {
      selector: '.valores-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'salvePreferencias' })
      ),
    },
    {
      selector: '.valores-feedback',
      content: <FeedbackTour feature={feature} intl={intl} />,
      position: 'left'
    },
  ];
};

export default atualizarValoresPageSteps;
