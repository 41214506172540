import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HelpCircle } from 'react-feather';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import DropdownSubmenu from 'src/componentes/DropdownSubmenu';

import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';

import ContentManager from 'src/componentes/content-manager';
import TermosUso from 'src/paginas/TermosUso';
import Sobre from './About';
import { capitalizeFirstLetter } from 'src/utils/string';
import { MODULOS } from 'src/utils/constants';
import HelpDrawer from './HelpDrawer';
import { getApplicationInfo } from 'src/services/auth';

const manuals = {
  'pt-br': {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/pt/topic/manual-de-treinamento-de-usuario',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/pt/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/pt/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/pt/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/pt/topic',
  },
  es: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/es/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/es/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/es/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/es/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/es/topic',
  },
  default: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/en/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/en/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/en/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/en/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/en/topic',
  },
};

function NavbarHelp() {
  const intl = useIntl();
  const history = useHistory();
  const [currentManualLink, setCurrentManualLink] = useState('');
  const [openHelp, setOpenHelp] = useState(false);
  const { module, user } = useAuth();

  const [podeVisualizarMatriz, setPodeVisualizarMatriz] = useState();
  const [podeVisualizarValoresDesatualizados, setPodeVisualizarValoresDesatualizados] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const dispatchMock = (action) => {
          if (action?.type === 'USER_LOGGED_IN') {
            setPodeVisualizarMatriz(
              action.user.usuarioAtualPodeVisualizarMatriz
            );
            setPodeVisualizarValoresDesatualizados(
              action.user.usuarioAtualPodeVisualizarValoresDesatualizados
            )
          }
        };

        // Chamando o método getApplicationInfo com um dispatch mock
        await getApplicationInfo(true)(dispatchMock);
      } catch (error) {
        console.error('Error fetching application info:', error);
      }
    };

    fetchData();
  }, []);

  const handleTerms = () => {
    ContentManager.addContent(<TermosUso history={history} />);
  };

  const handleAbout = () => {
    ContentManager.addContent(<Sobre />);
  };

  const handleHelpDrawer = () => {
    setOpenHelp(true);
  };

  useEffect(() => {
    const localeManual = manuals[intl.locale] || manuals['default'];
    const current =
      localeManual[module?.id] || localeManual[MODULOS.GESTAO_ESTRATEGICA];
    setCurrentManualLink(current);
  }, [module, intl]);

  return (
    <div>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <HelpCircle />
        </DropdownToggle>
        <DropdownMenu right>
          <a
            style={{ color: 'inherit', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
            href={currentManualLink}
          >
            <DropdownItem>
              {intl.formatMessage({ id: 'manualUsuario' })}
            </DropdownItem>
          </a>

          {!!user && user.usuarioPerfilAdmin && (
            <DropdownSubmenu
              isSubmenu
              direction="left"
              title={intl.formatMessage({ id: 'suporte' })}
            >
              <DropdownItem
                href="https://stratec.atlassian.net/servicedesk/customer/portals"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: 'portalAtendimento' })}
              </DropdownItem>
              <DropdownItem
                href="https://wa.me/5531989330912"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: 'whatsapp' })}
              </DropdownItem>
            </DropdownSubmenu>
          )}

          {(module?.id == MODULOS.GESTAO_ESTRATEGICA ||
            (module?.id == MODULOS.REMUNERACAO_VARIAVEL &&
              podeVisualizarMatriz)) && (
            <DropdownItem onClick={handleHelpDrawer}>
              {intl.formatMessage({ id: 'conhecerPlataforma' })}
            </DropdownItem>
          )}

          <DropdownItem onClick={() => history.push('/notas-versao')}>
            {intl.formatMessage({ id: 'notasVersao' })}
          </DropdownItem>

          <DropdownItem onClick={handleTerms}>
            {capitalizeFirstLetter(intl.formatMessage({ id: 'termosDeUso' }))}
          </DropdownItem>

          <DropdownItem onClick={handleAbout}>
            {intl.formatMessage({ id: 'sobre' })}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <HelpDrawer
        openHelp={openHelp}
        setOpenHelp={setOpenHelp}
        module={module}
        podeVisualizarMatriz={podeVisualizarMatriz}
        podeVisualizarValoresDesatualizados={podeVisualizarValoresDesatualizados}
      />
    </div>
  );
}

export default NavbarHelp;
